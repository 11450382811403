import React, { useState, useEffect } from "react"
import { css } from "@emotion/react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Navbar from "../components/Navbar"
import { SidebarObject } from "../components/Sidebar"
import Footer from "../components/Footer"
import "../styles/global.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from "styled-components"
import Poster from "../images/Poster.png"
import "./layout.css"
import { rhythm } from "../utils/typography"
import MobileNavbar from "../components/MobileNavBar"
import { TestimonialCarousel } from "../components/Testimonials"
import { QRCodePopup } from "../components/WeChatPopup"

const ChildContainer = styled.div`
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const MainSection = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
`

const TitleBanner = styled.div`
  width: 100%;
  height: calc(13vw + 200px);
  z-index: 0;
  /* background: rgb(1,174,240); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`

const BannerShade = styled.div `
  position: relative;
  height: calc(13vw + 200px);
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const TitleContainer = styled.div`
  position: relative;
  height: calc(13vw + 140px);
  top: 60px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const TitleH1 = styled.h1`
  color: #fff;
  font-size: 90px;
  text-align: center;
  margin: 0px;
  text-shadow: 4px 4px 6px black;

  @media screen and (max-width: 950px) {
    font-size: 60px;
  }

  @media screen and (max-width: 700px) {
    font-size: 50px;
  }

  @media screen and (max-width: 500px) {
    font-size: 35px;
  }
`

export const TitleH2 = styled.h2`
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  margin-left: calc(5px + 15vw);
  margin-right: calc(5px + 15vw);
  text-shadow: 1px 1px 1px black;

  @media screen and (max-width: 950px) {
    font-size: 18px;
  }

  @media screen and (max-width: 700px) {
    font-size: 15px;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`

export const Layout = props => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  const { sidebar, children, pageTitle, background, pageDescription, pageDescription2 } = props

  const [isOpen, setIsOpen] = useState(false)
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    const windowBig = window.innerWidth >= 1200
    setSidebarOpen(windowBig)
  }, [])
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen)
  }
  return (
    <div>
      <MobileNavbar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <TitleBanner
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <BannerShade>
          <TitleContainer>
            <TitleH1>{pageTitle}</TitleH1>
            <TitleH2>{pageDescription}<br/>{pageDescription2}</TitleH2>
          </TitleContainer>
        </BannerShade>
      </TitleBanner>
      <QRCodePopup/>
      <MainSection>
        <SidebarObject
          sidebarData={sidebar}
          isOpen={isSidebarOpen}
          toggle={toggleSidebar}
        />
        <ChildContainer>{children}</ChildContainer>
      </MainSection>
      <Footer />
    </div>
  )
}

export default Layout
